import "./styles.css";
import Textify from "./components/WebGLFont/Textify";
import { shaders } from "./components/WebGLFont/shaders";

const textify = new Textify({
  words: ["USPTU", "FAPP"], // выводимые слова
  fontColors: ["#000000", "#ff7400"], // цвета выводимых слов
  zoom: 300, // изначальная дальность камеры
  skybox: false, // включить/выключить skybox (фон гор)
  animation: false, // включить/выключить анимацию текста
  cameraMotion: false, // включить/выключить движение камеры по кругу
  font: 3, // шрифт по умолчанию
  shaders: shaders,
  worldTemplate: "arid2",
  rotation: [Math.PI, 0, 0]
});

global.textify = textify;