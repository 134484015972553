global.THREE = require("three"); // подключаем библиотеку three.js и сохраняем как свойство глобавльного объекта
const THREE = global.THREE; // сохраняем three.js в переменную THREE
const OrbitControls = require("three-orbit-controls")(THREE); // подключаем OrbitControls для управления свободной камерой
const loadFont = require("load-bmfont"); // функция загрузки шрифта
const createGeometry = require("three-bmfont-text"); // функция создания геометрии текстов
const MSDFShader = require("three-bmfont-text/shaders/msdf"); // шейдер

const fontFile = require("../../assets/Lato-Black.fnt"); // шрифт
const fontAtlas = require("../../assets/Lato-Black.png"); // набор шрифта


export default class Textify {
  constructor( opts = {} ) {
    // Настройки из index.js
    this.options = opts;

    // Пользовательские переменные
    this.geometrys = []; // геометрии отдельных слов
    this.meshes = []; // 3д объекты слов
    this.colors = this.options.fontColors.map( item => parseInt( item.replace("#", "0x") ) ); // массив цветов слов
    // this.cubes = []; // кубы
    this.skybox = null; // skybox (фон гор)
    this.skyboxFlag = this.options.skybox; // флаг управления скайбоксом
    this.animation = this.options.animation; // флаг упавления анимацией
    this.cameraMotion = {status: this.options.cameraMotion}; // объект движущейся камеры
    this.texture = null; // текстура текстов
    this.textOffset = 20; // отступы между слова (пробелы)
    this.positions = []; // массив позиций слов
    this.shaders = this.options.shaders; // шейдеры
    this.words = this.options.words; // слова для рендера по умолчанию

    // Переменные
    this.vars = {
      words: this.options.words,
      colors: this.colors,
      rotation: this.options.rotation,
      zoom: this.options.zoom,
    };

    // Сцена
    this.scene = new THREE.Scene();

    // Камера
    this.camera = new THREE.PerspectiveCamera(55, window.innerWidth / window.innerHeight, 45, 30000);
    this.camera.position.z = this.vars.zoom

    // Рендерер
    this.renderer = new THREE.WebGLRenderer({
      canvas: document.querySelector("#app"),
      antialias: true
    });

    this.renderer.setSize(window.innerWidth, window.innerHeight);
    this.renderer.setPixelRatio(window.devicePixelRatio);

    // Часы
    this.clock = new THREE.Clock();

    // EventListener'ы для контроля камеры
    this.controls = new OrbitControls(this.camera, this.renderer.domElement);

    // Инициализация графики по умолчанию
    this.init();
  }

  // Установка шейдера из номера шрифта
  setShaderFromNumber(n) {
    this.shader = {vertex: this.shaders[n].vertex, fragment: this.shaders[n].fragment};
  }

  // Создание skybox
  createWorld() {
    if (this.skybox) return;

    // Внещнее облако для хранения изображений
    const SITE_DONOR = "https://i.ibb.co";

    let materialArray = [];
    let texture_ft = new THREE.TextureLoader().load( `${SITE_DONOR}/PrDzvs4/ft.jpg`);
    let texture_bk = new THREE.TextureLoader().load( `${SITE_DONOR}/cxYHKQ7/bk.jpg`);
    let texture_up = new THREE.TextureLoader().load( `${SITE_DONOR}/bRjShnD/up.jpg`);
    let texture_dn = new THREE.TextureLoader().load( `${SITE_DONOR}/Drq2Ng8/dn.jpg`);
    let texture_rt = new THREE.TextureLoader().load( `${SITE_DONOR}/kcLqp6D/rt.jpg`);
    let texture_lf = new THREE.TextureLoader().load( `${SITE_DONOR}/s6GvrVh/lf.jpg`);
      
    materialArray.push(new THREE.MeshBasicMaterial( { map: texture_ft }));
    materialArray.push(new THREE.MeshBasicMaterial( { map: texture_bk }));
    materialArray.push(new THREE.MeshBasicMaterial( { map: texture_up }));
    materialArray.push(new THREE.MeshBasicMaterial( { map: texture_dn }));
    materialArray.push(new THREE.MeshBasicMaterial( { map: texture_rt }));
    materialArray.push(new THREE.MeshBasicMaterial( { map: texture_lf }));

    for (let i = 0; i < 6; i++) {
      materialArray[i].side = THREE.BackSide;
    }
    let skyboxGeo = new THREE.BoxGeometry( 10000, 10000, 10000);
    let skybox = new THREE.Mesh( skyboxGeo, materialArray );

    this.skybox = skybox;

    this.scene.add( this.skybox );
  }

  // Удаление skybox
  removeWorld() {
    if (!this.skybox) return;

    this.scene.remove( this.skybox );
    this.skybox = null;
  }

  // Активация кнопок управления тулбара
  activateControlButtons() {
    let bg = document.querySelector(".toolbar__bg"),
        anim = document.querySelector(".toolbar__anim"),
        cam = document.querySelector(".toolbar__cam"),
        font = document.querySelector(".toolbar__font"),
        text = document.querySelector(".toolbar__text");
      
    let toWhite = () => {
      let toolbar = document.querySelector("#toolbar");

      for (let item of toolbar.children) {
        item.firstElementChild.classList.toggle("white");
      }

      let copyright = document.querySelector(".copyright");
      copyright.classList.toggle("white");
    };

    if (this.skybox) {
      bg.classList.add("active");
      toWhite();
    }

    if (this.animation) {
      anim.classList.add("active");
    }

    if (this.cameraMotion.status) {
      cam.classList.add("active");
    }

    bg.onclick = () => {
      if (!this.skybox) this.createWorld();
      else this.removeWorld();

      bg.classList.toggle("active");
      toWhite();
    };

    anim.onclick = () => {
      this.animation = !this.animation;

      anim.classList.toggle("active");
    };

    cam.onclick = () => {
      this.cameraMotion.status = !this.cameraMotion.status;

      cam.classList.toggle("active");
    };

    font.onclick = () => {
      font.classList.add("active");

      let fontN = prompt("Напишите номер шрифта, от 1 до 6: ", "4");

      fontN = +fontN <= 7 && +fontN >= 1 ? +fontN - 1 : 4;

      this.setShaderFromNumber(fontN);
      this.changeTexts();
    };

    text.onclick = () => {
      text.classList.add("active");

      let newText = prompt("Напишите новый текст: ", "HELLO WORLD");

      let words = newText.split(" ");

      this.changeTexts(words);
    };
  }

  // Инициализация начальной позиции движущейся камеры
  createCameraMotion() {
    this.cameraMotion.angle = 360;
    this.cameraMotion.angularSpeed = THREE.Math.degToRad(20);
    this.cameraMotion.delta = 0;
    this.cameraMotion.radius = 300;
  }

  // Анимация движения камеры
  cameraMotionAnimation() {
    // this.cameraMotion.delta = this.clock.getDelta(); // контроль по пройденному времени
    this.cameraMotion.delta = 0.01;
    this.camera.position.x = Math.cos(this.cameraMotion.angle) * this.cameraMotion.radius;
    this.camera.position.z = Math.sin(this.cameraMotion.angle) * this.cameraMotion.radius;
    this.cameraMotion.angle += this.cameraMotion.angularSpeed * this.cameraMotion.delta; // приращение угла

    this.camera.lookAt(0, 0, 0);
  }

  // Инициализация графики по умолчанию
  init() {
    this.setShaderFromNumber( this.options.font ); 

    if (this.skyboxFlag) this.createWorld();
    else this.renderer.setClearColor( parseInt("0xf5f5f5") );

    this.createTexts();

    this.onResize();
    window.addEventListener("resize", () => this.onResize(), false);
    this.render();

    this.createCameraMotion();
    
    this.activateControlButtons();

    this.animate();

    this.copyright();
  }

  // Функция отрисовки копирайта
  copyright() {
    let div = document.createElement("div");
    div.innerHTML = `&copy; <a href="https://vk.com/vakil.gayfullin" target="_blank">Вакиль Гайфуллин</a> 2020`;
    div.className = "copyright";
    document.body.append(div);
  }

  // Получение размеров 3д объектов на сцене
  getBoundaryGeometry = function(obj) {
    let modelBoundingBox;

    modelBoundingBox = new THREE.Box3().setFromObject(obj);
    modelBoundingBox.size = {};
    modelBoundingBox.size.x = modelBoundingBox.max.x - modelBoundingBox.min.x;
    modelBoundingBox.size.y = modelBoundingBox.max.y - modelBoundingBox.min.y;
    modelBoundingBox.size.z = modelBoundingBox.max.z - modelBoundingBox.min.z;

    return modelBoundingBox.size;
  };
  
  // Получение позиций отдельных слов
  getMeshesPositions() {
    this.positions = [];
    let geometrys = [];

    for (let mesh of this.meshes) {
      geometrys.push( this.getBoundaryGeometry(mesh) );
    }

    if (geometrys.length === 0) return;

    let fullWidth = 0, fullHeight = 0;

    fullHeight = geometrys[0].y;

    fullWidth = geometrys.reduce( (sum, item) => sum += item.x, 0 );

    fullWidth += this.textOffset * (geometrys.length - 1);

    let leftX = Math.floor(- fullWidth / 2),
        topY = Math.floor(- fullHeight / 2);

    this.positions.push( [leftX, topY, 0] );

    for (let i = 1; i < geometrys.length; i++) {
      let prevGeo = geometrys[i - 1],
          prevPos = this.positions[i - 1];

      let x = prevPos[0] + prevGeo.x + this.textOffset;
      
      this.positions.push( [x, topY, 0] );
    }
  }

  // Установка правильных позиций слов (центрирование)
  setMeshesPositions() {
    for (let i = 0; i < this.meshes.length; i++) {
      this.meshes[i].position.set( ...this.positions[i] );
    }
  }

  // Добавление текста
  addTexts() {
    for (let mesh of this.meshes) {
      this.scene.add(mesh);
    }
  }

  // Загрузка шрифта
  loadBMF(words, time) {
    // Create geometry of packed glyphs
    loadFont(fontFile, (err, font) => {
      this.geometrys = [];

      for (let word of words) {
        let geometry = createGeometry({
          font,
          text: word
        });

        this.geometrys.push(geometry);
      }
    });

    return new Promise( (resolve, reject) => {
      // Load texture containing font glyphs
      this.loader = new THREE.TextureLoader();
      this.loader.load(fontAtlas, texture => {
        this.texture = texture;
        setTimeout(() => {
          resolve(Date.now() - time);
        }, 50);
      });
    });
  }

  // Создание текста
  createTexts(words = this.vars.words) {
    this.loadBMF(words, Date.now() )
      .then( (time) => {
        this.meshes = [];

        for (let i = 0; i < this.geometrys.length; i++) {
          let mesh = this.createMesh(this.geometrys[i], this.texture, this.vars.colors[i] );
          this.meshes.push(mesh);
        }

        this.getMeshesPositions();
        this.setMeshesPositions();
        this.addTexts();
      });
  }

  // Удаление текста
  removeTexts() {
    for (let mesh of this.meshes) {
      this.scene.remove(mesh);
    }

    this.meshes = [];
  }

  // Замена текста
  changeTexts(words = this.words) {
    this.removeTexts();
    this.words = words;
    this.createTexts(words);
  }

  // Создание отдельных слов
  createMesh(geometry, texture, color) {
    // Material
    let material = new THREE.RawShaderMaterial(
      MSDFShader({
        vertexShader: this.shader.vertex,
        fragmentShader: this.shader.fragment,
        color: color ? color : 0,
        map: texture,
        side: THREE.DoubleSide,
        transparent: true,
        negate: false
      })
    );

    // Create time variable from prestablished shader uniforms
    material.uniforms.time = { type: "f", value: 0.0 };

    // Mesh
    let mesh = new THREE.Mesh(geometry, material);
    mesh.position.set(0, 0, 0);
    mesh.rotation.set(...this.vars.rotation);
    return mesh;
  }

  // Функция реагирования на изменение значений окна браузера
  onResize() {
    let w = window.innerWidth;
    let h = window.innerHeight;

    w < 640
      ? (this.camera.position.z = 900)
      : (this.camera.position.z = this.vars.zoom);

    this.camera.aspect = w / h;
    this.camera.updateProjectionMatrix();

    this.renderer.setSize(w, h);
  }

  // Подключение анимации
  animate() {
    requestAnimationFrame(this.animate.bind(this));

    this.render();
  }

  // Отрисовка графики (~60fps)
  render() {
    this.renderer.render(this.scene, this.camera);

    if (this.animation) {
      for (let mesh of this.meshes) {
        mesh.material.uniforms.time.value = this.clock.getElapsedTime();
        mesh.material.uniformsNeedUpdate = true;
      }
    }

    if (this.cameraMotion.status) {
      this.cameraMotionAnimation();
    }
  }
}
